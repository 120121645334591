import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
// import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { LockOutlined, LogoutOutlined } from '@ant-design/icons';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleChangePassword: () => void;
}

const ProfileTab = ({ handleLogout, handleChangePassword }: Props) => {
  const [selectedIndex] = useState(0);

  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
  //   setSelectedIndex(index);
  // };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* {<ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View }Profile" />
      </ListItemButton> */}
      <ListItemButton selected={selectedIndex === 2} onClick={handleChangePassword}>
        <ListItemIcon>
          <LockOutlined rev />
        </ListItemIcon>
        <ListItemText primary="เปลี่ยนรหัสผ่าน" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined rev />
        </ListItemIcon>
        <ListItemText primary="ลงชื่อออก" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
